<template>
  <div
    :id="vnodeId"
    class="cvt-editor-icon"
    :class="classes"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop.prevent="openIconPicker"
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <drag-and-drop-zone
      :z-index="dropzoneZIndex"
      @dropped="addElementToSide"
    ></drag-and-drop-zone>

    <div :class="iconWrapperClasses">
      <a
        v-if="href"
        :href="href"
        target="_blank"
        :class="iconLinkWrapperClasses"
      >
        <icon-template :set="set" :selector="selector" :class="iconClasses" />
      </a>
      <icon-template
        v-if="!href"
        :set="set"
        :selector="selector"
        :class="iconClasses"
      />
    </div>
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutations from '../../../../store/modules/mutations'
import FbVNode from '../../../base/FbVNode.vue'
import IconTemplate from './IconTemplate.vue'

export default FbVNode.extend({
  components: {
    IconTemplate,
  },
  props: {
    set: {
      type: String,
      required: true,
    },
    selector: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
    },
    href: String,
  },
  data() {
    return {
      name: 'Icon',
    }
  },
  computed: {
    iconName() {
      return `Icon (${this.set} - ${this.selector})`
    },
    styles() {
      return {
        icon: {
          fontSize: `${this.size}rem!important`,
          color: this.themeColorHSL,
          zIndex: 0,
        },
        iconWrapper: {
          width: '100%',
          height: '100%',
          ...this.spacingStyle,
        },
      }
    },
    iconWrapperClasses() {
      return {
        'd-flex': true,
        [this.stylesheet.classes.iconWrapper]: true,
      }
    },
    iconClasses() {
      return {
        [this.stylesheet.classes.icon]: true,
        [this.bootstrapAlignment]: !this.href,
      }
    },
    iconLinkWrapperClasses() {
      return {
        [this.bootstrapAlignment]: true,
      }
    },
  },
  methods: {
    ...mapMutations({
      showIconPicker: mutations.ICON_PICKER_SHOW,
    }),
    openIconPicker() {
      if (this.isGridBuilder) {
        return
      }

      this.showIconPicker(true)
    },
  },
})
</script>

<style lang="scss">
.cvt-editor-icon {
  position: relative;

  // if div#page-engine has grid-builder class only then apply this drag-and-drop-handle style
  .grid-builder & {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}

</style>
