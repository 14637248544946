
<div class="alt-buttons-v3" :class="{'regenerate-btn-styles': showRegenerate}">
  <cvt-button
    v-if="showDuplicate"
    class="ab-duplicate-btn"
    color="light"
    size="md"
    :outlined="false"
    :icon="(iconConfig.propertiesSidebar.copy || 'copy')"
    @click="$emit('duplicate')"
  />

  <cvt-button
    v-if="showRegenerate"
    class="ab-regenerate-btn"
    color="light"
    size="md"
    :outlined="false"
    :icon="'refresh'"
    @click="() => {}"
  />

  <cvt-button
    v-if="showDelete"
    class="ab-delete-btn"
    color="light"
    size="md"
    :outlined="false"
    :icon="(iconConfig.propertiesSidebar.remove || 'trash')"
    @click="$emit('remove')"
  />
</div>
