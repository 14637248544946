<template>
  <div class="">
    <div v-if="!disableAltText" class="row">
      <div class="col text-capitalize">
        {{ $t('element.property.image.alt.label') }}
      </div>
      <div class="col-12">
        <cvt-input
          v-model="alt"
          type="text"
          :placeholder="$t('element.property.image.alt.alt_message')"
        />
      </div>
    </div>

    <div v-if="!disableAltText" class="divider" />

    <div class="row" data-identity="normalBtnActionsDropdownWrap">
      <div class="col-md-12">
        <cvt-select
          mode="dropdown"
          :value="action"
          label="Image Click Action"
          :theme="mode"
          :background-mode="mode"
          @input="(value) => (action = value)"
        >
          <option
            v-for="optionAction in actions"
            :key="optionAction.value"
            :value="optionAction.value"
            v-text="optionAction.label"
          ></option>
        </cvt-select>
      </div>
    </div>

    <div v-if="!disableAltText" class="divider" />

    <div v-if="linkActive" class="row mt-2">
      <div v-if="OrgName !== orgNameFreshWork" class="col text-capitalize">
        {{ $t('element.property.link') }}
      </div>
      <div v-else class="col align-items-center">
        <h6 :class="textColorClass">Hyperlink image</h6>
      </div>
      <div class="col-12">
        <url-input
          v-model="href"
          type="text"
          :placeholder="$t('element.property.image.alt.link_message')"
        />
      </div>
    </div>

    <div v-if="anchorActive" class="row no-gutters">
      <div class="col">
        <h6 :class="textColorClass">Anchor To</h6>
      </div>
      <div class="col-md-12">
        <section-list v-model="anchor" :value="anchor" />
      </div>
    </div>

    <div
      v-if="popupActive && isGridBuilder"
      class="row no-gutters"
      data-identity="normalBtnPopupsDropdownWrap"
    >
      <div class="col-md-12">
        <popup-list-alt
          :key="selected.vnodeId"
          :value="popup"
          @configure-popup="configurePopup"
          @delete-popup="removePopup"
          @change="(value) => (popup = value)"
        />
      </div>

      <div class="col-md-12 justify-content-center">
        <cvt-button
          class="m-1"
          :outlined="true"
          :text="$t('e.popup.create.a.popup')"
          :full-width="true"
          @click="createNewPopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '@/js/store/modules/mutations'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import SectionList from '@/js/components/common/SectionList.vue'
import PopupListAlt from '@/js/components/common/PopupListAlt.vue'

import { Block, CPopupBuilder, GridContainer } from '@/js/lib/helper-classes'

export default {
  components: { PopupListAlt, SectionList },
  props: {
    disableAltText: Boolean,
  },
  data() {
    return {
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      deltaRef: getters.DELTA_SOURCE,
      isGridBuilder: getters.SITE_HAS_GRIDBUILDER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      mode: ({ globalTheme }) => globalTheme.mode,
    }),
    textColorClass() {
      return { 'text-white': this.mode === 'DARK' }
    },
    actions() {
      return _.omitBy(
        [
          {
            label: this.$t('element.property.button.action.option.url'),
            value: 'link',
          },
          {
            label: this.$t('element.property.button.action.option.anchor'),
            value: 'anchor',
          },
          this.PopupEnabled && !this.popupBuilderOpen && this.isGridBuilder
            ? { label: 'Open in a popup', value: 'popup' }
            : null,
        ],
        _.isNull,
      )
    },
    PopupEnabled() {
      return this.config.POPUP_ENABLED || false
    },
    anchorActive() {
      return this.action === 'anchor'
    },
    linkActive() {
      return this.action === 'link'
    },
    popupActive() {
      return this.action === 'popup'
    },
    action: {
      get() {
        return _.get(this.selected, 'action', 'link')
      },
      set(value) {
        this.clearActions(value)
        return this.updateProp({ key: 'action', value: value })
      },
    },
    anchor: {
      get() {
        return _.get(this.selected, 'anchor', '')
      },
      set(value) {
        return this.updateProp({ key: 'anchor', value })
      },
    },
    popup: {
      get() {
        return this.selected.popup
      },
      set(value) {
        if (value !== null) {
          this.updateProp({
            key: 'popup',
            value: {
              ...value,
              popupToggleScript: `togglePopup('${value.popupVNode}')`,
            },
          })
          this.setPopupIdx(value.index)
          this.setPopupPath(value.index)
        }
      },
    },
    href: {
      get() {
        return this.selected?.href || ''
      },

      set: _.debounce(function (value) {
        return this.updateProp({
          key: 'href',
          value,
        })
      }, 1000),
    },
    alt: {
      get() {
        return this.selected?.alt || ''
      },

      set: _.debounce(function ({ target }) {
        return this.updateProp({
          key: 'alt',
          value: target.value,
        })
      }, 1000),
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      setPopupPath: actions.POPUP_SET_PATH,
      deletePopupData: actions.POPUP_BUILDER_DELETE_POPUP_DATA,
      togglePopup: actions.POPUP_BUILDER_TOGGLE,
      pushNewPopup: actions.POPUP_BUILDER_PUSH_NEW_BLOCK,
      ignore: mutations.SELECTOR_IGNORE,
    }),
    ...mapMutations({
      setPopupIdx: mutations.SET_POPUP_IDX,
      deselectItem: mutations.SELECTOR_DESELECT,
      closeAssetManager: mutations.ASSET_MANAGER_TOGGLE,
    }),
    removePopup(popup) {
      if (this.popup?.index && this.popup?.index !== -1) {
        let selectedPath = this.selected.path()
        let sitePath = selectedPath.split('/vdom')[0]

        let rf = this.deltaRef.ref(sitePath + '/popup/' + popup.index)
        rf.remove()

        let DataPath = selectedPath + '/data/props/'
        this.deltaRef.ref(DataPath + 'popup').set({ disabled: true })

        this.setPopupIdx(-1)
        this.setPopupPath(-1)

        this.ignore()
        this.deletePopupData(popup.index)
      }
    },
    async configurePopup() {
      if (this.popup?.index) {
        this.setPopupIdx(this.popup.index)
        this.setPopupPath(this.popup.index)

        this.closeAssetManager()
        this.togglePopup()
        this.deselectItem()
      } else {
        console.debug('Popup is NOT set ')
      }
    },
    createNewPopup() {
      const gridContainer = new Block(true)
      gridContainer.children.push(new GridContainer())

      const popUpWrapper = new CPopupBuilder()
      const popUpWrapperId = popUpWrapper.data.props.vnodeId
      popUpWrapper.children.push(gridContainer)
      this.pushNewPopup(popUpWrapper)
        .then(async (d) => {
          const lastIdx = d.snapshot.val().length - 1
          this.setPopupIdx(lastIdx)
          this.setPopupPath(lastIdx)

          this.updateProp({
            key: 'popup',
            value: {
              index: lastIdx,
              name: `popup ${lastIdx}`,
              popupVNode: popUpWrapperId,
            },
          })
        })
        .then(() => {
          this.closeAssetManager()
          this.togglePopup()
          this.deselectItem()
        })
    },
    clearActions(incomingAction) {
      const actions = ['link', 'popup', 'anchor'].filter(
        (x) => x !== incomingAction,
      )
      actions.map((x) => {
        let selectedPath = this.selected.path()
        let DataPath = selectedPath + '/data/props/'

        if (x === 'popup') {
          this.deltaRef.ref(DataPath + 'popup').set({ disabled: true })
        } else if (x === 'link') {
          this.deltaRef.ref(DataPath + 'href').set('')
        } else {
          this.deltaRef.ref(DataPath + x).set('')
        }
      })
    },
  },
}
</script>

<style lang="css"></style>
